import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';
import { StaticImage } from 'gatsby-plugin-image';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden'
  },
  image: {
    // minHeight: 400,
    width: '100%',
    height: '483px',
    filter: 'brightness(50%)',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: 'auto'
    }
  },
  textWhite: {
    color: 'white'
  },
  title: {
    fontWeight: 'bold'
  },
  section: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    paddingTop: 0,
    paddingBottom: 0
  },
}));

const Hero = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Image
        src={require('../../../../../assets/images/qeerio/feature-cover-inventory.webp').default}
        alt="Feature cover"
        className={classes.image}
        lazyProps={{ width: '100%', height: '480px' }}
      />
      <Section className={classes.section}>
        <SectionHeader
          title="Features"
          subtitle={<span>Everything for a <span style={{ color: 'rgba(255,100,83)' }}>straightforward</span> daily <span style={{ color: 'rgba(255,100,83)' }}>inventory</span> management</span>}
          align="left"
          data-aos="fade-up"
          disableGutter
          titleProps={{
            className: clsx(classes.title, classes.textWhite),
            variant: 'h3',
          }}
          subtitleProps={{
            className: classes.textWhite
          }}
        />
      </Section>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string
};

export default Hero;
