export const services = [
  {
    icon: 'fas fa-user-plus',
    title: 'Collaboration',
    description:
      'Invite your team members to work on your inventory'
  },
  {
    icon: 'fas fa-ban',
    title: 'Permissions',
    description:
      'Specify what team members can do in your inventory'
  },
  {
    icon: 'fas fa-clock',
    title: 'History',
    description:
      'Keep track of all the actions and moves done across your inventory'
  },
  {
    icon: 'fas fa-qrcode',
    title: 'QR code',
    description:
      'All items can be tracked, found and managed using QR codes'
  },
  {
    icon: 'fas fa-i-cursor',
    title: 'Custom fields',
    description:
      'Add any details you need you your items'
  },
  {
    icon: 'fas fa-search',
    title: 'Lightning-fast search',
    description:
      'Search though millions of items instantly'
  },
  {
    icon: 'fas fa-file-excel',
    title: 'Excel import and export',
    description:
      'Import and Export your inventory quickly with excel'
  },
  {
    icon: 'fas fa-box',
    title: 'Automatic inventory update',
    description:
      'Your inventory is updates with items that were not returned after an event'
  },
  {
    icon: 'fas fa-book',
    title: 'Double booking detection',
    description:
      'Never book the same item for 2 different events ever again!'
  }
];
